import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RelationshipManagerService {

  public baseUrl = environment.apiBaseUrl + '/relationshipManager';
  constructor(private http:HttpClient) { }
  getAllRelationshipManager = () => {
    return this.http.get(`${this.baseUrl}/getAll`);
  }
  saveRelationshipManager=(data)=>{
    return this.http.post(`${this.baseUrl}/saveRM`,data)
  }
  updateRelationshipManager=(data)=>{
    return this.http.put(`${this.baseUrl}/updateRM`,data)
  }
  updateRMbyCustomer=(data)=>{
    return this.http.put(`${this.baseUrl}/updateRMbyCus`,data)
  }
  getCustomerList=(data)=>{
    return this.http.put(`${this.baseUrl}/getCusList`,data)
  }
  deleteRM=(data)=>{
    console.log(data,'ffff');

    return this.http.put(`${this.baseUrl}/deleteRM`,data)
  }
}
